import adminLayout from '@/layouts/Admin'
import currentRouteMixin from '@/mixins/current-route.js'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'ColorsView',
    data() {
        return {
            color: {},
            currentColorId: null,
            fileAudio: null,
            fileCoverImage: null
        }
    },
    mixins: [currentRouteMixin],
    components: {
        adminLayout
    },
    mounted() {
        this.currentColorId = this.currentRoute().params.id

        this.getColor()
    },
    methods: {
        getColor() {
            const self = this
            const selects = '?select=id,name,code,audio'

            httpAxios({
                url: self.$backendUrl + '/api/v1/colors/' + self.currentColorId + selects,
                method: 'GET'
            }).then(function(response) {
                self.color = response.data.color
            })
        },
        deleteColor() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/colors/' + self.currentColorId,
                method: 'DELETE'
            }).then(function() {
                self.$router.push({ name: 'admin.colors' })
            })
        },
        updateColor() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            formData.append('name', self.color.name)
            formData.append('code', self.color.code)
            if(self.fileAudio) formData.append('audio', self.fileAudio)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/colors/' + self.currentColorId,
                method: 'POST',
                data: formData
            }).then(function(response) {
                self.color = response.data.color

                self.notifySuccess()
            })
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        }
    }
}